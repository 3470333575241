<template>
    <div class="screen-page-container" @click="toFullOrExit">
        <img
            src="https://cdn.spbcn.org/spbcnWechat/fusai_screen_logo_left.png"
            @click="refreshFn"
            class="left-logo"
            alt=""
        />
        <img
            src="https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_welcome_logo2.png"
            class="right-logo"
            alt=""
        />
        <div :style="pageStyle">
            <div class="screen-page-welcome" v-if="rankList.length === 0">
                <div class="sw-main">
                    <img
                        src="https://cdn.spbcn.org/spbcnWechat/screen_welcome_img01.png"
                        class="screen_welcome_img1"
                        alt=""
                    />
                    <div class="sw-main-right">
                        <img
                            src="https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_welcome_img2.png"
                            class="screen_welcome_img2"
                            alt=""
                        />
                        <div class="sw-saiqu">全国总决选复活赛</div>
                        <img
                            src="https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_welcome_img3.png"
                            class="screen_welcome_img3"
                            alt=""
                        />
                    </div>
                </div>
                <div class="bottom-bar"></div>
            </div>
            <div class="screen-page-rank" v-else>
                <div class="screen-back-img1"></div>
                <div class="screen-back-img2"></div>
                <div class="countdown_class" v-if="isCarousel === 'true'"><span style="color: #ce1c1a; font-weight: bolder; font-size: 60px;">{{secondNum}}s</span>后切换组别</div>
                <div class="rank">
                    <div class="rank-title">
                        <img class="rank-left-title-img" src="https://cdn.spbcn.org/spbcnWechat/feng_talk.png" alt="">
                        <img
                            src="https://cdn.spbcn.org/spbcnWechat/ftalk_hero_img.png"
                            alt=""
                            class="ftalk_heropic"
                        />
                        <div class="rank-center-back">
                            <div class="rank-title-level-back">
                                <img
                                    class="rank-title-left-img"
                                    src="https://cdn.spbcn.org/spbcnWechat/ftalk_left_rank_img.png"
                                    alt=""
                                />
                                <div class="rank-title-level">
                                    {{ groupTypeStr }}
                                </div>
                            </div>
                            <img
                                src="https://cdn.spbcn.org/spbcnWechat/ftalk_rank_img.png"
                                class="rank-title-img"
                                alt=""
                            />
                        </div>
                        <img
                            src="https://cdn.spbcn.org/spbcnWechat/ftalk_right_rank_img.png"
                            class="rank-right-title-img"
                            alt=""
                        />
                    </div>
                    <div :class="'rank-panel-back' + queryForm.level">
                        <div class="rank-titleStr-back">
                            <div class="rank-titleStr">
                                <span class="rank-1">排名</span>
                                <span class="rank-2">国赛号码牌-姓名</span>
                                <span class="rank-3">积分</span>
                                <span class="rank-4">有组别名次</span>
                                <span class="rank-5">答题时长</span>
                                <span class="rank-6">是否晋级</span>
                            </div>
                            <div class="rank-titleStr">
                                <span class="rank-1">排名</span>
                                <span class="rank-2">国赛号码牌-姓名</span>
                                <span class="rank-3">积分</span>
                                <span class="rank-4">有组别名次</span>
                                <span class="rank-5">答题时长</span>
                                <span class="rank-6">是否晋级</span>
                            </div>
                        </div>

                        <div class="rank-panel">
                            <div
                                v-for="(list, i) of rankList"
                                :key="i"
                                class="rank-item-back"
                            >
                                <div class="rank-blank">
                                    <div
                                        class="rank-item"
                                        v-for="(item, j) of list"
                                        :key="j"
                                    >
                                        <div
                                            class="rank-number-back"
                                            v-if="item.promoted === 1"
                                        >
                                            <img
                                                class="rank-number-img"
                                                src="https://cdn.spbcn.org/chineseWord/applets/images/rank1.png"
                                                alt=""
                                            />
                                        </div>
                                        <div class="rank-number-back" v-else>
                                            <div class="rank-number">
                                                {{ (item.promoted === 1 || item.promoted === 0) ? item.fk : '-' }}
                                            </div>
                                        </div>
                                        <div class="rank-name">
                                            {{ item.nationalnum }}-{{
                                                item.userName
                                            }}
                                        </div>
                                        <div class="rank-integral">
                                            {{ item.integral }}
                                        </div>
                                        <div class="rank-City-back">
                                            <div class="rank-City">
                                                {{
                                                    item.ranking > 1000
                                                        ? "-"
                                                        : item.ranking
                                                }}
                                            </div>
                                        </div>
                                        <div class="rank-second">
                                            {{ item.seconds }}s
                                        </div>
                                        <div class="rank-promoted-back">
                                            <img
                                                class="rank-promoted-1"
                                                v-if="item.promoted === 0"
                                                src="https://cdn.spbcn.org/spbcnWechat/large-screen-red.png"
                                                alt=""
                                            />
                                            <img
                                                class="rank-promoted-1"
                                                v-else-if="item.promoted === 1"
                                                src="https://cdn.spbcn.org/spbcnWechat/large-screen-green.png"
                                                alt=""
                                            />
                                            <img
                                                class="rank-promoted-1"
                                                v-else
                                                src="https://cdn.spbcn.org/spbcnWechat/large-screen-yellow.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="lineview"></div>
                        </div>
                        <div class="bottom-view">
                            <img
                                class="rank-promoted-1"
                                src="https://cdn.spbcn.org/spbcnWechat/large-screen-green.png"
                                alt=""
                            />
                            <span class="rank-promoted-2">晋级</span>
                            <img
                                class="rank-promoted-1"
                                src="https://cdn.spbcn.org/spbcnWechat/large-screen-red.png"
                                alt=""
                            />
                            <span class="rank-promoted-2">淘汰</span>
                            <img
                                class="rank-promoted-1"
                                src="https://cdn.spbcn.org/spbcnWechat/large-screen-yellow.png"
                                alt=""
                            />
                            <span class="rank-promoted-2">待定</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getNationalScreenData } from '@/http/api'
export default {
  data () {
    return {
      prefixUrl: 'https://erp.spbcn.org/zhangtr/V3/BeeScreen/index.html',
      secondNum: 30,
      timer: '',
      toggleTimer: '',
      pageStyle: {
        width: '100%',
        height: '100%'
      },
      code: '',
      // status 状态:1:未开始 2:比赛中 3:比赛结束 4:发布成绩
      // 1和2  跳转首页    3跳转到(成绩统计中)  4跳转到(排名)
      status: -1,
      rankList: [],
      size: 10, // 一列展示多少
      groupTypeStr: '',
      value: 0,
      isCarousel: this.$route.query.isCarousel
    }
  },
  beforeDestroy () {
    this.over()
  },
  created () {
    if (this.$route.query.isCarousel === 'false') {
      const channel = new BroadcastChannel('myChannel')
      channel.onmessage = function (event) {
        if (event.data.key === 'changeBigScreen') {
          window.location.href = process.env.NODE_ENV === 'production' ? 'https://erp.spbcn.org/zhangtr/V3/BeeScreen/index.html' : 'https://test.erp.spbcn.org/zhangtr/V3/BeeScreen/index.html'
        }
      }
    }
    this.queryForm = {
      season: this.$route.query.season,
      level: this.$route.query.level
    }
    this.groupTypeStr = this.$route.query.groupStr.slice(0, 3) + '复活赛'
    this.start(this.$route.query.isCarousel)
  },

  methods: {
    getScreenSize () {
      const w = window.innerWidth // 屏幕宽
      const h = window.innerHeight // 屏幕高
      const standardScale = 16 / 9
      const actualScale = w / h
      const calcWidth = 16 * h / 9
      const calcHeight = 9 * w / 16
      if (actualScale < standardScale) {
        this.pageStyle.width = '100%'
        this.pageStyle.height = parseInt(calcHeight) + 'px'
      } else if (actualScale < standardScale) {
        this.pageStyle.width = parseInt(calcWidth) + 'px'
        this.pageStyle.height = '100%'
      }
    },
    getGroupStr () {
      switch (this.queryForm.level) {
        case '0':
          this.groupTypeStr = '小初组复活赛'
          break
        case '1':
          this.groupTypeStr = '小中组复活赛'
          break
        case '2':
          this.groupTypeStr = '小高组复活赛'
          break
        case '3':
          this.groupTypeStr = '初中组复活赛'
          break
        default:
          break
      }
    },
    start (isCarousel) {
      if (this.value === 0) {
        this.getDataList()
      }
      if (isCarousel === 'true') {
        this.countdownTimer = setInterval(this.countdownChange, 1000) // 注意: 第一个参数为方法名的时候不要加括号;
        this.timer = setInterval(this.valChange, 4000) // 注意: 第一个参数为方法名的时候不要加括号;
        this.toggleTimer = setInterval(this.toggleChange, 30000)
      } else {
        this.timer = setInterval(this.valChange, 4000) // 注意: 第一个参数为方法名的时候不要加括号;
      }
    },
    // 30秒切换组别
    toggleChange () {
      this.secondNum = 30
      var levelNum = Number(this.queryForm.level)
      if (levelNum === 3) {
        levelNum = 0
      } else {
        levelNum++
      }
      this.queryForm.level = levelNum + ''
      this.getGroupStr()
      this.getDataList()
    },
    valChange () {
      this.getDataList()
    },
    countdownChange () {
      this.secondNum--
    },
    over () {
      clearInterval(this.timer)
      clearInterval(this.toggleTimer)
    },
    getDataList () {
      this.value++
      getNationalScreenData(this.queryForm).then(res => {
        this.rankList = this.sliceArray(res.data)
      })
    },
    sliceArray (arr) {
      const arrNum = Math.ceil(arr.length / this.size, 10) // Math.ceil()向上取整的方法，用来计算拆分后数组的长度
      let index = 0 // 定义初始索引
      let resIndex = 0 // 用来保存每次拆分的长度
      const result = []
      while (index < arrNum) {
        result[index] = arr.slice(resIndex, this.size + resIndex)
        resIndex += this.size
        index++
      }
      return result
    },
    // 左上角logo 刷新状态
    refreshFn () {
      this.getStatusByCode()
    },
    // 右上角logo全屏切换
    toFullOrExit () {
      this.isFull = !this.isFull
      if (this.isFull) {
        // this.fullImg = require('@/assets/img/efs.png')
        this.requestFullScreen()
      } else {
        // this.fullImg = require('@/assets/img/fs.png')
        this.exitFullscreen()
      }
    },
    requestFullScreen () {
      const de = document.documentElement
      if (de.requestFullscreen) {
        de.requestFullscreen()
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen()
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen()
      }
    },
    exitFullscreen () {
      const de = document
      if (de.exitFullscreen) {
        de.exitFullscreen()
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen()
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.screen-page-container {
    // width: 100vw;
    // height: 100vh;
    background-color: #2424ce;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.left-logo {
    position: absolute;
    left: 42px;
    top: 40px;
    height: 42px;
}
.right-logo {
    position: absolute;
    z-index: 99;
    right: 42px;
    top: 30px;
    height: 60px;
}

// 欢迎首页
.screen-page-welcome {
    width: 100%;
    height: 100%;
    background-image: url(https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_welcome_bg1.jpg);
    background-size: auto 92%;
    background-position: right bottom;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottom-bar {
    width: 100vw;
    height: 30px;
    background-color: #ce954b;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
.sw-main {
    display: flex;
    // align-items: center;
    justify-content: center;
}
.sw-saiqu {
    font-family: "zzgflh";
    font-size: 100px;
    color: #fff;
}
.screen_welcome_img1 {
    height: 540px;
}
.screen_welcome_img2 {
    height: 176px;
}
.screen_welcome_img3 {
    margin-left: 230px;
    height: 188px;
}
.rank-promoted-back {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rank-promoted-1 {
    height: 30px;
    width: 30px;
}
// 排名页
.screen-page-rank {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    // background-image: url(https://cdn.spbcn.org/spbcnWechat/screen_welcome_bg3.png);
    // background-size: 100% auto;
    // background-repeat: no-repeat;
    // background-position: left bottom;
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.loading-img {
    height: 120px;
}
.rank {
    position: relative;
    z-index: 100;
}
.screen-back-img1 {
    width: 30%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url(https://cdn.spbcn.org/spbcnWechat/screen_welcome_bg3.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: right top;
}
.screen-back-img2 {
    width: 30%;
    height: 100%;
    z-index: -1;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-image: url(https://cdn.spbcn.org/spbcnWechat/screen_welcome_bg2.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left bottom;
}
.countdown_class{
    font-size: 40px;
    color: white;
    width: auto;
    position: absolute;
    left: 100px;
    top: 160px;
}
.rank-title {
    width: 100%;
    z-index: 100;
    margin: 0 auto;
    padding-top: 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.rank-title-img {
    width: 278px;
    height: 80px;
    margin-top: 20px;
}
.rank-title-level {
    height: 80px;
    text-align: center;
    line-height: 80px;
    font-size: 40px;
    margin-left: 10px;
    font-family: "zzgflh";
    color: #ffffff;
}
.rank-number-back {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rank-number {
    width: 30px;
    height: 30px;
    font-size: 20px;
    text-align: center;
    border-radius: 15px;
    background: black;
    color: white;
}
.rank-desc {
    font-family: "xique";
    font-size: 34px;
    color: #000;
    text-align: center;
    margin-top: 8px;
    span {
        color: #fff;
        margin-left: 10px;
    }
}

.rank-panel-back0 {
    width: 90%;
    height: 750px;
    z-index: 100;
    overflow-x: auto;
    background: linear-gradient(#facd6e, #fff597);
    border: 4px solid #d88024;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
}
.rank-panel-back1 {
    width: 90%;
    height: 750px;
    z-index: 100;
    overflow-x: auto;
    background: linear-gradient(#f6f6f6, #dfdfdf);
    border: 4px solid #d88024;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.rank-panel-back2 {
    width: 90%;
    height: 750px;
    z-index: 100;
    overflow-x: auto;
    background: linear-gradient(#facd6e, #fff597);
    border: 4px solid #d88024;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.rank-panel-back3 {
    width: 90%;
    height: 750px;
    z-index: 100;
    overflow-x: auto;
    background: linear-gradient(#ffffff, #d3d3d3);
    border: 4px solid #d88024;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.rank-panel {
    width: 100%;
    height: 604px;
    margin-top: 20px;
    position: relative;
    overflow-x: auto;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
}
.rank-blank {
    width: 100%;
    font-size: 28px;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.rank-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.rank-index {
    width: 40px;
    height: 40px;
    font-size: 22px;
    color: #fff;
    background-color: #000;
    border-radius: 40px;
    margin-right: 42px;
    margin-left: 8px;
    text-align: center;
    line-height: 34px;
}
.rank-name {
    font-size: 24px;
    width: 180px;
    text-align: center;
}
.rank-item-00 {
    margin-bottom: 15px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rank-titleStr-back {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #d41f1c;
}
.rank-titleStr {
    width: 45%;
    height: 80px;
    font-weight: bold;
    display: flex;
    color: white;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
.rank-item-02 {
    margin-bottom: 15px;
}
.rank-index-00 {
    width: 58px;
    height: 50px;
    background-color: transparent;
    background-image: url(https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_rank_icon1.png);
    background-size: contain;
    background-position: center;
    margin-left: 0;
    margin-right: 34px;
}
.rank-index-01 {
    width: 58px;
    height: 50px;
    background-color: transparent;
    background-image: url(https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_rank_icon2.png);
    background-size: contain;
    background-position: center;
    margin-left: 0;
    margin-right: 34px;
}
.rank-index-02 {
    width: 58px;
    height: 50px;
    background-color: transparent;
    background-image: url(https://cdn.spbcn.org/spbcnappneed/bigScreen/screen_rank_icon3.png);
    background-size: contain;
    background-position: center;
    margin-left: 0;
    margin-right: 34px;
}
.rank-item-back {
    width: 45%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.rank-integral {
    width: 90px;
    color: red;
    text-align: center;
}
.rank-City {
    width: 60px;
    height: 40px;
    border-radius: 10px;
    background: rgb(33, 136, 2);
    line-height: 40px;
    color: rgb(239, 239, 239);
    text-align: center;
}
.rank-City-back {
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rank-second {
    width: 100px;
    color: blue;
    text-align: center;
}
.lineview {
    width: 3px;
    height: 95%;
    position: absolute;
    left: 49.5%;
    background: #6c6c6c;
}
.rank-1 {
    width: 80px;
    height: 30px;
    font-size: 20px;
    text-align: center;
}
.rank-2 {
    width: 180px;
    text-align: center;
}
.rank-3 {
    width: 90px;
    text-align: center;
}
.rank-4 {
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rank-5 {
    width: 100px;
    text-align: center;
}
.rank-6 {
    width: 120px;
    text-align: center;
}
.bottom-view {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.rank-promoted-2 {
    font-size: 20px;
    color: black;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 30px;
}
.ftalk_heropic {
    width: 195px;
    height: 200px;
}
.rank-center-back {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.ftalk_strpic {
    width: 100px;
    height: 51px;
    left: 500px;
    top: 65px;
    position: absolute;
}
.rank-right-title-img {
    width: 20px;
    height: 200px;
    margin-left: 20px;
}
.rank-title-level-back {
    display: flex;
    background: #da221e;
    padding: 0px 20px;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.rank-title-left-img {
    width: 64px;
    height: 70px;
}

.rank-number-img {
    width: 30px;
    height: 49px;
}
.rank-left-title-img{
    width: auto;
    height: 100px;
    margin-right: 20px;
}
</style>
